var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

// Récupération de la valeur de la constante NO_LAZYLOAD pour appliquer le paramètre aux swiper
var isLazyLoad = is_lazy_load ? false : true;

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {
    return str.replace(rex_php_tags, "");
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {
    return str.replace(rex_script_tags, "");
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {
    return str.replace(rex_comments_tags, "");
}

function stripCombo(str) {
    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

/**
 *
 * @param product_id : id du produit
 * @param regroup_ref_id : id du regroupement (si existant)
 * use in : app/views/_components/product/achat_express.php
 */
function achatExpressV2(product_id, regroup_ref_id) {
    var btn = $(this);

    // Loader
    btn.parent('.form_submit').addClass('loading');

    var elem = regroup_ref_id ? '_' + product_id + regroup_ref_id : '_' + product_id;
    var form = document.getElementById('prod_info' + elem);

    var color_id = form.elements['itm_color'].value;
    var size_id = form.elements['itm_size'].value;
    var tailles = $("#tailleProd" + elem);

    $("#ligne_pointure_error" + elem).remove();

    // Vérification si la taille sélectionnée n'est pas désactivée ce qui permet d'afficher l'alerte stock
    if ($('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)) != undefined && $('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)).length == 1 && $('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)).hasClass('disabled')) {
        $(this).parent().hide();
        $('#bloc_add_alert' + elem).show();
    } else { // On ajoute bien le produit en faisant les vérifications sur la taille et la couleur
        // On regarde si la personne a sélectionenr la taille et la couleur
        if (color_id !== '' && size_id !== '') {
            if (tailles.length && tailles.val() == "") {
                var txt_error = '<div class="productErrorFieldset" id="ligne_pointure_error' + elem + '">' + Translator.translate('quick_purchase_choose_a_size') + '</div>';
                btn.parent('.form_submit').removeClass('loading');
                $(txt_error).insertBefore($("#size_list" + elem));
            }
            waitingAddToBasket(event, form, '', regroup_ref_id);
            setTimeout(function () {
                btn.parent('.form_submit').removeClass('loading');
            }, 2000);
        } else {
            if (color_id === '' && size_id === '') {
                var txt_error = '<div class="ligne_form productErrorFieldset" id="ligne_pointure_error' + elem + '">' + Translator.translate('quick_purchase_choose_a_size_and_a_color') + '</div>';
            } else if (color_id === '') {
                var txt_error = '<div class="ligne_form productErrorFieldset" id="ligne_pointure_error' + elem + '">' + Translator.translate('quick_purchase_choose_a_color') + '</div>';
            } else if (size_id === '') {
                var txt_error = '<div class="ligne_form productErrorFieldset" id="ligne_pointure_error' + elem + '">' + Translator.translate('quick_purchase_choose_a_size') + '</div>';
            }
            // Loader
            btn.parent('.form_submit').removeClass('loading');
            $(txt_error).insertBefore($("#ligne_pointure" + elem));
        }
    }
}

// Called upon size change in product page
function onItemSizeChange(form, event, itm_rr_id) {
    var control;
    var is_wishlist = $('#wishlist').length > 0;
    if ($('#tunnel_right_col').length > 0) {
        is_wishlist = false;
    }
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    if ($('#tunnel_right_col').length > 0) {
        is_wishlist = false;
    }

    if (this.checked) {

        if (window.dataLayer !== undefined) {
            window.dataLayer.push({
                'event': 'changeSize',
                'produit': {
                    'size_value': ($(this).attr('data-nom')),
                }
            });

            if (window.dataLayer[0].product) {
                window.dataLayer[0].product.size = ($(this).attr('data-nom'));
            }
        }

        if ($('#sizeerror').is(':visible')) {
            $('#sizeerror').hide();
        }

        var id = form.getValue('produit_id');
        var elem = is_achat_express_v2 ? '_' + id + itm_rr_id : '';

        control = form.elements.namedItem('tailleProd');
        control.value = this.value.trim();
        // En fonction de la couleur on regarde si le produit est disponible
        getAvailableQuantity($('#produit_principal' + elem).val(), $('#qteProd' + elem).val(), 'product', event, itm_rr_id, is_wishlist, this);
        // On regarde si le produti est dejà dans la wishlist
        if (!is_achat_express_v2) {
            checkProductInWishlist();
        }

        var btnAddCart = $('#btn_add_cart');
        var addBasketPreco = Translator.translate('add_basket_preco');

        if (btnAddCart.data('type') === 'add_basket_preco') {
            btnAddCart.val(addBasketPreco);
        }
    }
}

// Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
function topCartDesappear() {
    var show_top_cart_parent = $("#show_top_cart");
    if ($("body.cart").length) {
        $(".block_top_item.my_basket a").addClass("shop_b");
        show_top_cart_parent.removeClass("hover");
        $(".smallbasketExpress").removeClass("actif");
        $("#shad_menu").removeClass("actif");
    } else {
        show_top_cart_parent.removeClass("hover");
        $(".smallbasketExpress").removeClass("actif");
        $("#shad_menu").removeClass("actif");
        $(".cart ").removeClass("shop_b");
        $(".cart ").addClass("shop");
    }
}

// Refreshes available quantity
function getAvailableQuantity(id, qty, from, event, itm_rr_id, RefreshQuantity = true) {
    let addProductToCart = false;
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    let form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id + itm_rr_id);

    if (!form) {
        form = document.getElementById('item_add_form');
    }

    let is_achat_express = $('#is_achat_express_v2').val() == 1;
    let elem = is_achat_express ? '_' + id + itm_rr_id : '';
    let productId = form.getValue('produit_id');
    let displayQuantity = ($('.display_quantity').length > 0 && $('.display_quantity').val() === '1');
    let productColor = form.getValue('idCouleur_' + id + itm_rr_id);

    if (typeof (productColor) == 'undefined') {
        productColor = form.getValue('idCouleur');
    }

    let val_color = form.getValue(productColor);
    if (val_color === '0') {
        val_color = 'none';
    }

    let productSize = form.getValue('idTaille');
    let val_size = form.getValue(productSize);
    if (val_size === '0') {
        val_size = 'none';
    }

    if (productId !== '' && val_color !== '' && val_size !== '') {
        let data = {
            idprod: productId,
            idcouleur: val_color,
            idtaille: val_size,
            json: '1'
        };

        if (from === 'basket') {
            data.suff = productId;
        }

        $.ajax({
            url: path_relative_root + 'ajax_liste_qte.php',
            type: 'get',
            async: false, // Should be async but would then need a loader so...
            dataType: 'json',
            data: data,
            success: function (res) {

                // Refresh bloc quantity depending on the size selected
                if (RefreshQuantity && displayQuantity && typeof res.content !== "undefined") {

                    let qteElem = $("#liste_qte");

                    if (qteElem.length) {
                        qteElem.html(res.content);
                        qteElem.removeClass('cache');
                    }
                }

                // if there is no sfs
                if (typeof res.is_sfs === "undefined" || (typeof res.is_sfs !== "undefined" && res.is_sfs === false)) {

                    // Page rayon
                    if (is_achat_express) {

                        if (res.disable_addtocart) {

                            // Display bloc alert stock
                            $("#bloc_add_alert" + elem).show();
                            $("#bloc_size_not_available" + elem).show();

                            // Hide bloc ajout panier
                            var prodInfoElement = $("#prod_info" + elem);

                            if (prodInfoElement.closest('.product_info_wrapper').length === 0) {
                                prodInfoElement.find('.productSizeFieldset').hide();
                            }
                        } else {
                            addProductToCart = true;

                            // Hide bloc alert stock
                            $("#bloc_add_alert" + elem).hide();
                            $("#bloc_size_not_available" + elem).hide();

                            // Display bloc ajout panier
                            $("#prod_info" + elem + " .rollover_left").show();
                            $("#prod_info" + elem + " .bloc_add_color").show();
                            $("#prod_info" + elem + " .productSizeFieldset").show();
                        }

                    } else { // Fiche produit
                        if (res.disable_addtocart) {
                            $("#bloc_add_alert").removeClass("cache");
                            $("#btn_alert_stock").show();
                            $(".product_info_wrapper #size_is_not_available").show();
                            $("#bloc_add_basket").hide();
                            $("#btn_add_cart").hide();
                        } else {
                            $("#bloc_add_alert").addClass("cache");
                            $("#btn_alert_stock").hide();
                            $(".product_info_wrapper #size_is_not_available").hide();
                            $("#bloc_add_basket").show();
                            $("#btn_add_cart").show();
                        }
                    }
                } else { // Si y'a sfs
                    if (is_achat_express) {

                        if (res.stock_tampon < 1) {

                            // Display bloc alert stock
                            $("#bloc_add_alert" + elem).show();
                            $("#bloc_size_not_available" + elem).show();

                        } else {
                            addProductToCart = true;

                            // Hide bloc alert stock
                            $("#bloc_add_alert" + elem).hide();
                            $("#bloc_size_not_available" + elem).hide();

                            // Display bloc ajout panier
                            $("#prod_info" + elem + " .rollover_left").show();
                            $("#prod_info" + elem + " .bloc_add_color").show();
                            $("#prod_info" + elem + " .productSizeFieldset").show();
                        }
                    } else { //Fiche produit
                        if (res.stock_tampon < 1) {
                            $("#bloc_add_alert").removeClass("cache");
                            $("#btn_alert_stock").show();
                            $(".product_info_wrapper #size_is_not_available").show();
                            $("#bloc_add_basket").hide();
                            $("#btn_add_cart").hide();
                        } else {
                            $("#bloc_add_alert").addClass("cache");
                            $("#btn_alert_stock").hide();
                            $(".product_info_wrapper #size_is_not_available").hide();
                            $("#bloc_add_basket").show();
                            $("#btn_add_cart").show();
                        }
                    }
                }

                // Custom quantity selector update for FP
                if ($('#product_stock_counter').length && parseInt(res.stock_tampon)) {
                    $('#product_stock_counter').val(parseInt(res.stock_tampon))
                }
            }
        });

        if (addProductToCart) {
            waitingAddToBasket(event, form, from, itm_rr_id);
        }
    }
}

/***** FUNCTION PUSH INTO DATALAYER  *****/
function pushIntoDatalayer(data) {
    if (window.dataLayer !== undefined) {
        if (typeof (data) == 'object') {
            window.dataLayer.push(data);
        } else if (typeof (data) == 'string') {
            window.dataLayer.push(JSON.parse(data));
        }
    }
}

function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css("display");
    if (!loader.parent().hasClass("loading")) {
        loader.parent().addClass("loading");

        switch (action) {
            case 0:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuthTop,
                    1,
                    path_relative_root
                );
                break;

            case 1:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwdTop,
                    1,
                    path_relative_root
                );
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function () {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass("loading");
                }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données
                //window.setTimeout(checkAllForm, 1000, tabError, 0, path_relative_root, id_form);
                break;

            case 4:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuth,
                    1,
                    path_relative_root
                );
                break;

            case 5:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwd,
                    1,
                    path_relative_root
                );
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (
                    checkAllForm(
                        tabErrorGrattez,
                        0,
                        path_relative_root,
                        id_form
                    )
                ) {
                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

// Client disconnexion

function ajaxDeconnect(path_relative_root) {

    var provenance = window.location.pathname,
        cpt_client = new RegExp(/(?:adresses|mes_infos|newsletter|parrainage|mon-compte|facture-liste|avoir-liste|commande_detail|panier|livraison-kiala|livraison|paiement|banque|erreur_cmd|erreur_commande|banque_refus|remerciements|endereco|newsletter-pt|conta|conta-lista|haver-lista|encomenda_detalhes|cesta|remessa|pagamento|banco|erreur_cmd_pt|erros-encomenda|banco-recusa|agradecimento)/),
        response;

    // Get the current page's name
    provenance = provenance.substr(provenance.lastIndexOf('/'), provenance.lastIndexOf('.'));

    // This actually disconnects the client
    response = ajax_file(path_relative_root + 'ajax_deconnect.php');

    // If there was an error
    if (!response)
        alert('Erreur lors de la déconnexion.');

    // Else if the current page can't be displayed when not logged in: redirect
    else if (cpt_client.test(provenance))
        window.location.href = path_relative_root + create_link('connexion_login');

    // Else: refresh
    else window.location.reload();

    // If the page hasn't been reloaded: remove the loader and return false
    hideLoader();

    return false;
}

$(function () {
    //GET AND ADD TEXTE PLACEHOLDER ABOVE INPUT
    $('#search_cp').on('input', function () {
        var inputValue = $(this).val().trim();

        if (inputValue !== "") {
            $('.placeholder').css('display', 'block');
        } else {
            $('.placeholder').css('display', 'none');
        }
    });

    $(document).ready(function () {
        // Get the placeholder value of the input
        var placeholderValue = $('#search_cp').attr('placeholder');
        // Create a new label element with the placeholder value
        var labelElement = $('<label>').text(placeholderValue).addClass("placeholder");
        // Place the label before the input element
        $('#search_cp').after(labelElement);
    });

    // SWIPER PUSH STORE DETAIL
    if ($('#store_slider').length) {
        var storeSwiper = new Swiper('#store_slider', {
            slidesPerView: 1,
            centeredSlides: true,
        });
    }
});

$(window).on("load", function() {
    // Show popin fairlymade
    $("#open-popup-fairlymade").on("click", function() {
        openMultiShad("popup-fairlymade");
    });

    // Close popin fairlymade
    $("#button_close_fairlymade").on("click", function(event) {
        event.stopPropagation();
        closeMultiShad("popup-fairlymade");
    });
});

// Called upon color change in product page
function onItemColorChange(form, is_regroup, itm_rr_id) {
    var control, prod_id;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

    if (this.checked) {
        control = form.elements.namedItem(form.getValue('idCouleur'));
        control.value = this.value.trim();
        prod_id = form.getValue('itm_id_' + control.value + itm_rr_id).trim();
        itm_rr_id = itm_rr_id !== '' ? '_' + itm_rr_id.split('_')[1] : '';
        if (is_regroup) {
            loadProduct.call(form, prod_id, 'couleur', '', itm_rr_id);
        } else {
            // On regarde si le produit est dejà dans la wishlist
            if (!is_achat_express_v2) {
                checkProductInWishlist();
            }

            var img = $(this).data('pic');

            changeVisu('img_large', img);

            // On definie les tailles qui ont encore du stock
            setAvailableSizes.call(this);
        }
    }
}

function changeVisu(field, path) {
    var new_img;

    field = document.getElementById(field);

    if (field && field.parentElement) {
        new_img = new Image();
        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener('load', function () {
            this.alt = field.alt;

            if (field.parentElement) {
                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.onclick = function () {
            zoomImg();
        }

        new_img.src = path;
        $("#zoom_box_wrapper #img_large").attr("src", path);
    }

    // Simpler way doesn't seem to work well with lazyloader
    // return document.getElementById(field).src = path;
    var productSwiper = new Swiper(".productSwiper:not(.w-slider-container)", {});
}

// LIGHTBOX OPENING
function openMultiShad(id) {
    $("#" + id).addClass("actif");
    $("body").addClass("fixed");
    $('#shad').addClass('actif');

    $("#shad").on("click", function () {
        closeMultiShad(id);
    });

    // Scroll addresse account
    if (id == "lightboxCustomerAddress" && $('body').hasClass('mobile') && $('#wrapper_form_adresse').length) {
        $('#wrapper_form_adresse').overlayScrollbars({
            overflowBehavior: {
                x: 'hidden',
            },
        });
    }

    // Scroll addresse cart
    if (id == "delivery_home_choice" && $('body').hasClass('mobile') && $('.wrapper_select_adr').length) {
        $('.modlink').on('click', function () {
            // Set an interval to check for the existence of the div
            var checkFormExist = setInterval(function () {
                if ($('.wrapper_form_adresse').length) {
                    $('.wrapper_form_adresse').overlayScrollbars({
                        overflowBehavior: {
                            x: 'hidden',
                        },
                    });

                    // Stop the interval once the element is found
                    clearInterval(checkFormExist);
                }
            }, 100); // Check every 100 milliseconds
        });
    }

    // Scroll addresse relai
    if ($('#' + id).hasClass('delivery_lightbox') && $('body').hasClass('mobile') && $('.wrapper_locate_relais').length) {
        // Reset classes since not compatible with "changeRelay" function from app tunnel_v3.js
        $("body").removeClass("fixed");
        $('#shad').removeClass('actif');

        // Update classes
        $("body").addClass("noscroll");
        $('#shade').addClass('actif');

        $('.wrapper_locate_relais').overlayScrollbars({
            overflowBehavior: {
                x: 'hidden',
            },
        });
    }

    // Return order
    if ($('#' + id).hasClass('popup_numero_retour') && $('body').hasClass('mobile')) {
        $('.wrap_produit').overlayScrollbars({
            overflowBehavior: {
                x: 'hidden',
            },
        });
    }

    if (id == "selection-offer") {
        // Wrapping elements in one div 
        $('#selection-offer .js-form-selection-offer').each(function () {
            $(this).children().slice(2).wrapAll('<div class="wrap_product_info" />');
        });
    }

    // Fairlymade
    if (id == 'popup-fairlymade') {
        $('#popup-fairlymade').insertAfter('.page_produit');
        $('#shad').addClass('forFairlyMade');
    }
}

function closeMultiShad(id) {
    $('#' + id).removeClass('actif');
    $('body').removeClass('fixed');
    
    // Exception for Sizeguide popin
    if (id == 'sizeguideBox') {
        $('.toggleCarac.toggle_block_sizeguide').removeClass('actif');
    }
    
    // Fairlymade
    if (id == 'popup-fairlymade') {
        $('#popup-fairlymade').addClass('closing');
        
        setTimeout(() => {
            $('#popup-fairlymade').removeClass('closing');
            $('#shad').removeClass('forFairlyMade');
        }, 1000);
    }
    
    setTimeout(function () {
        $('#shad').removeClass('actif');
    }, 500);
    
    $("#shad").off("click"); // Remove all close listeners on the shad. Avoids multiple listeners on the shad when opening multiple popins
}

/*
 * Permet de changer l'action et le visuel du bouton d'ajout à la wishlist
 * Aurelien Renault
 * @param in_wishlist : true ou false selon si dans wishlist
 * @param product_id : l'id du produit
 * @param itm_rr_id : l'id du regroupement
 */

function changeBtnAddWishlist(in_wishlist) {
    var btn = $("#addToWishlistButton");

    if (in_wishlist) {
        btn.attr("onclick", 'addToWishlist.call(this);return false;');
        btn.attr("href", "#");
        btn.addClass("existToWishlistButton");
    } else {
        btn.attr("onclick", 'addToWishlist.call(this);return false;');
        btn.attr("href", "#");
        btn.removeClass("existToWishlistButton");
    }
}

function showTabFaq(node_id, level, question_id) {
    if (question_id !== null && question_id !== undefined) {
        //show answer
        $(".faq_reponse:not(.faq_reponse_" + question_id + ")").slideUp("slow", function () {
                $(this).addClass("cache");
            }
        );

        $(".faq_reponse_" + question_id).slideToggle("slow", function () {
            $(this).toggleClass("cache");
        });

        $(".faq_question:not(.faq_question_" + question_id + ")").removeClass("active");
        $(".faq_question_" + question_id).toggleClass("active");
    } else {
        var parent_id = $(".faq_theme_" + node_id).data("parent");

        //active
        $(".faq_element:not(.faq_theme_" + node_id + ")").removeClass("active");
        $(".faq_theme_" + node_id).toggleClass("active");
        $(".faq_theme_" + parent_id).toggleClass("active");

        //show questions
        $(".faq_questions:not(.faq_questions_" + node_id + ", .faq_questions_" + parent_id + " )").slideUp("slow", function () {
            $(this).addClass("cache");
        });

        $(".faq_questions_" + node_id).slideToggle("slow", function () {
            $(this).toggleClass("cache");
        });

        // Hide other node of same level
        $(".faq_level_" + level + ":not(.faq_theme_" + node_id + ")").each(
            function (idx) {
                var id = $(this).data("id");

                $(".faq_level_parent_" + id).slideUp("slow", function () {
                    $(".faq_level_parent_" + id).addClass("cache").trigger("classChange");
                });
            }
        );

        // Show/hide child of selected node
        $(".faq_level_parent_" + node_id).slideToggle("slow", function () {
            $(".faq_level_parent_" + node_id).toggleClass("cache").trigger("classChange");
        });

        $(".faq_element").on("classChange", function () {
            var id = $(this).data("id");

            if ($(this).hasClass("cache")) {
                $(".faq_level_parent_" + id).addClass("cache").trigger("classChange");
            }
        });
    }
}

function changeLocale(locale, uri) {
    $.ajax({
        url: path_relative_root + "ajax_get_uri_by_lang.php",
        type: "post",
        data: {lang: locale, url: uri},
        success: function (data) {
            if (data != "") {
                var force = "";

                if (uri == "/") {
                    force = "?force";
                }

                location.href = data + force;
            }
        },
    });
}

// Alters item quantity in cart
function chanQteList() {
    var item = this.getValue('item_id', parseFloat);
    var loader = document.getElementById('qte_loader_' + item);

    var old_qte = parseInt($(this).find('.selected_qte').val());
    var new_qte = parseInt($(this).find('.qnt_prod').val());
    var rank = $(this).index('.qte_selector');
    this.qtty_select_wrap.style.display = 'none';
    loader.style.display = 'block';

    if (window.dataLayer !== undefined) {
        var product = productFieldObject[rank];

        if (new_qte > old_qte) {
            product.quantity = (new_qte - old_qte);
            dataLayer.push({
                "event": "addToCart",
                "ecommerce": {
                    'currencyCode': siteCurrency.code,
                    "add": {
                        "products": [product]
                    }
                }
            });
        } else if (new_qte < old_qte) {
            product.quantity = (old_qte - new_qte);
            dataLayer.push({
                "event": "removeFromCart",
                "ecommerce": {
                    "remove": {
                        "products": [product]
                    }
                }
            });
        }
    }

    $.ajax({
        url: path_relative_root + create_link("order_basket"),
        type: 'post',
        data: {
            action: 'del',
            panierId: item,
            cad: 'non',
            pointcad: 0,
            qteProd: new_qte,
            change_qte: true
        },
        cache: false,
        success: function (res) {
            try {
                res = JSON.parse(res);
            } catch (e) {
                console.error('JSON ERROR: could not parse data');
                return false;
            }

            res = $('<div />').html(res.datas);

            document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();
            if (wShop.$refs.wCouponForm) {
                wShop.$refs.wCouponForm.reloadBloc += 1;
            }

            // Cart slider initialization
            var sliderCart = $('#main_cart_wrapper #basketAssocWrapper');

            if (sliderCart.length) {
                sliderCart.remove();
            }

            //update top cart
            var response = ajax_file(create_link('ajax_show_basket'));
            document.getElementById('cart_top').innerHTML = response;

        },
        complete: function () {
            var total = $('#cart_top .link_item_cart').attr('data-cart'),
                cart = Translator.translate('mon_panier'),
                text;
            if (total <= 1) {
                text = Translator.translate('one_product');
            } else {
                text = Translator.translate('2_product');
            }
            ;

            //Simulates scroll (2px) to load images
            setTimeout(function () {
                window.scrollTo(0, $(window).scrollTop() + 3);
            }, 100);
        }
    });
}

function process_type_preco() {
    var val = $(".bloc_lightbox_preco.actif").attr("data-value");

    $.ajax({
        type: "post",
        data: {type_preco: val},
        url: path_relative_root + "ajax_choose_preco.php",
        success: function (response) {
            if (response.status == "success") {
                if ($("input[name=preco_choice]").length == 0) {
                    closeMultiShad("lightbox_preco");
                }


                $.each(response.transportation, function (i, item) {
                    if (typeof (array_transporteurs_datas[i]) != 'undefined') {
                        array_transporteurs_datas[i]["fdp"][0] = item["unit"];
                        array_transporteurs_datas[i]["fdp"][1] = item["cent"];
                        array_transporteurs_datas[i]["fdp"]["format"] = item["format"];
                        $(".choix_delivery[data-id-transporteur=" + i + "] .pricetag").html(item["format"]);
                        $(".choix_delivery[data-id-transporteur=" + i + "] .price_tag").html(item["format"]);
                    }
                });

                if (val == "several") {
                    $(".choix_delivery_relais").hide();
                    $(".choix_delivery_postal:first .choix_delivery").click();
                    $(".choix_delivery_postal:first .choix_livraison").attr("checked", "checked");
                    $(".choix_delivery_postal:first .choix_livraison").change();
                } else {
                    $(".choix_delivery_relais").show();
                    $(".choix_delivery_relais:first").click();
                    $(".choix_delivery_relais:first .choix_livraison").attr("checked", "checked");
                    $(".choix_delivery_relais:first .choix_livraison").change();
                }

                $("#tunnel_right_col #cart_total").css("visibility", "visible");
            }
        },
    });
}